import payload_plugin_YY1o5tilDM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.25.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_XxHCONwSrt from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dYuniBFogV from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_HyJwlCEhi6 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hso5Pm36IM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_8ESJ5xjxMi from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hKlO3Fo84I from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kRodz8fnCe from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_XqMsE7peMN from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.25.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/.nuxt/components.plugin.mjs";
import prefetch_client_a5pVGpn4NL from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._csrpumz2llmdyervtc4mbdzctu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_TgMiCF6UPw from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_v_byr7bfcpidfmxnujx5xwfokbzi/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_s4a0292i9p from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_v_byr7bfcpidfmxnujx5xwfokbzi/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_VcnTJD8fFF from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.20.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_v_byr7bfcpidfmxnujx5xwfokbzi/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_NWFjiuZzaz from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.25.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_zOpOdp6Ljt from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+icon@1.9.1_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.10.1_terser@5.36.0___2twalwuuitl6tef6gq77ms7uha/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_wV8eD1nB9V from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.6_typescri_be7pdbkbfx3glhhlbp5yxp5jlq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import Vue3Lottie_client_2RwzQTxwz0 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/Vue3Lottie.client.ts";
import maska_EdO7pVjy2z from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/maska.ts";
import vmoney_client_bVSBf9GwIM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/vmoney.client.ts";
import vue_query_JC9gnf7CA1 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/vue-query.ts";
export default [
  payload_plugin_YY1o5tilDM,
  revive_payload_client_XxHCONwSrt,
  unhead_dYuniBFogV,
  router_HyJwlCEhi6,
  payload_client_hso5Pm36IM,
  navigation_repaint_client_8ESJ5xjxMi,
  check_outdated_build_client_hKlO3Fo84I,
  chunk_reload_client_kRodz8fnCe,
  plugin_vue3_XqMsE7peMN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_a5pVGpn4NL,
  slideovers_TgMiCF6UPw,
  modals_s4a0292i9p,
  colors_VcnTJD8fFF,
  plugin_client_NWFjiuZzaz,
  plugin_zOpOdp6Ljt,
  plugin_wy0B721ODc,
  plugin_wV8eD1nB9V,
  Vue3Lottie_client_2RwzQTxwz0,
  maska_EdO7pVjy2z,
  vmoney_client_bVSBf9GwIM,
  vue_query_JC9gnf7CA1
]