export const useTheme = () => {
  const route = useRoute();
  const availableThemes = [
    "claro",
    "vemqda",
    "claro_nacional",
    "claro_afiliados",
    "clube_conecta",
  ];
  const config = useAppConfig();
  const runtime = useRuntimeConfig();

  const DEFAULT_THEME = computed(() => {
    const { URL_API } = runtime.public;

    if (URL_API.includes("vemqda")) {
      return "vemqda";
    }

    return "vemqda";
  });

  const theme = computed(() => {
    if (availableThemes.includes(route?.params?.theme as string)) {
      return route.params.theme as string;
    }

    return DEFAULT_THEME.value;
  });

  const isVemqda = computed(() => {
    return theme.value === "vemqda";
  });

  const isClaroNacional = computed(() => {
    return theme.value === "claro_nacional";
  });

  const isClaroAfiliados = computed(() => {
    return theme.value === "claro_afiliados";
  });

  const isClubeConecta = computed(() => {
    return theme.value === "clube_conecta";
  });

  onBeforeMount(() => {
    config.ui.primary = theme.value;
  });

  return {
    theme,
    isVemqda,
    isClaroNacional,
    isClaroAfiliados,
    isClubeConecta,
  };
};
